<template>
  <div id="root">
    <div id="app">
      <Header></Header>
      <HeaderMobile></HeaderMobile>
      <Main></Main>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "./components/header/Header.vue";
import HeaderMobile from "./components/headerMobile/HeaderMobile.vue";
import Main from "./components/main/Main.vue";
import Footer from "./components/footer/Footer.vue";
import { EventBus } from "./util/eventBus.js";

export default {
  name: "App",
  components: {
    Main,
    Header,
    HeaderMobile,
    Footer,
  },

  created() {
    EventBus.$on("changeRouter", (color) => {
      this.handleResize()
    });
    window.addEventListener("resize", this.handleResize);
  },
  destoryed() {
    EventBus.$off("changeRouter", () => {});
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
  },
  methods: {
    handleResize() {
      EventBus.$emit("windowResize");
    },
  },
};
</script>

<style>
@import "index.scss";

#root {
  /*position: relative;*/
  z-index: 0;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat-Italic", Arial, Helvetica, sans-serif;
}

#app {
  font-family: "Montserrat-Italic", Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
</style>
