<template>
  <section>
    <div class="banner">
      <div class="onlyDesktop">
        <div class="banner">
          <picture>
            <source
              srcset="../../assets/contact/contact-bg.webp"
              class="banner"
              type="image/webp"
              media="(max-width: 1920px)"
            />
            <img src="../../assets/contact/contact-bg.webp" class="banner" />
          </picture>
          <div class="banner-box">
            <picture class="banner-service zoom">
              <source
                srcset="../../assets/contact/contact-banner.webp"
                class="banner"
                type="image/webp"
                media="(max-width: 1920px)"
              />
              <img
                src="../../assets/contact/contact-banner.webp"
                :style="{ width: calBannerWidth }"
                class="banner"
              />
            </picture>
          </div>
        </div>
      </div>
      <div class="onlyMobile">
        <picture>
          <source
            srcset="../../assets/contact/m-contact-no.webp"
            class="banner"
            type="image/webp"
            media="(max-width: 1024px)"
          />
          <source
            srcset="../../assets/contact/m-contact-no.webp"
            class="banner"
            type="image/webp"
            media="(min-width: 576px)"
          />
          <img src="../../assets/contact/m-contact-no.webp" class="banner" />
        </picture>
        <div class="desc">
          Our primary goal is to be a trustworthy provider with whom clients can
          communicate at any time.
        </div>
      </div>
    </div>
    <div class="content">
      <div class="message">
        <div class="linder">
          <img src="../../assets/contact/icon1.png" />
          <div class="linder-content linder-content-desc linder-spacing">
            17256 &nbsp;S&nbsp; Main St, &nbsp;Gardena, <br />CA &nbsp;90248 &nbsp;USA
          </div>
        </div>
        <div class="linder">
          <img src="../../assets/contact/icon3.png" />
          <div class="linder-content linder-content-desc linder-spacing">(310) 961 9060</div>
        </div>
        <div class="linder-last">
          <img src="../../assets/contact/icon2.png" />
          <div class="linder-content">
            <div class="linder-content-row">
              <div class="linder-content-title">Air Forwarding</div>
              <div class="linder-content-desc">air@ultraaircargo.com</div>
            </div>
            <div class="linder-content-row">
              <div class="linder-content-title">Ocean Forwarding</div>
              <div class="linder-content-desc">ocean@ultraaircargo.com</div>
            </div>
            <div class="linder-content-row">
              <div class="linder-content-title">Warehousing and Distribution</div>
              <div class="linder-content-desc">ocean@ultraaircargo.com</div>
            </div>
            <div>
              <div class="linder-content-title">Transloading</div>
              <div class="linder-content-desc">ocean@ultraaircargo.com</div>
            </div>
          </div>
        </div>
      </div>
      <div class="maps-box">
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26495.79114177004!2d-118.33462080425672!3d33.89032621122913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2cac06df89aaf%3A0x6ff4898c191bd48!2s17256%20Main%20St%2C%20Gardena%2C%20CA%2090248!5e0!3m2!1sen!2sus!4v1700117410492!5m2!1sen!2sus"
            class="maps"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { EventBus } from "../../util/eventBus.js";
export default {
  name: "Home",
  data() {
    return {
      scaleBannerWidth: 736,
      pcMaxWidth: 1920,
      mobileMaxWidth: 576,
    };
  },
  mounted() {},
  computed: {
    calBannerWidth: {
      get() {
        this.handleWindowResize();
        return this.scaleBannerWidth;
      },
      set(newValue) {
        this.handleWindowResize();
      },
    },
  },
  created() {
    EventBus.$on("windowResize", this.handleWindowResize);
  },
  destroyed() {
    EventBus.$off("windowResize", this.handleWindowResize);
  },

  methods: {
    handleWindowResize() {
      if (window.innerWidth > this.pcMaxWidth) return;
      this.scaleBannerWidth = (736 * window.innerWidth) / 1920 + "px";
    }
  },
};
</script>

<style scoped lang="scss">
@import "contact.scss";
</style>
