// src/router/index.js
import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/components/home/Home.vue';
import Contact from '@/components/contact/Contact.vue'
import About from '@/components/about/About.vue';
import Services from '@/components/services/Services.vue';
import Partners from '@/components/partners/Partners.vue';
Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/services',
        name: 'Services',
        component: Services,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/partners',
        name: 'Partners',
        component: Partners
    }
    
];

const router = new Router({
    mode: 'history',
    routes
});

export default router;
