<template>
  <section>
    <div class="banner">
      <div class="onlyDesktop">
        <div class="banner">
          <picture>
            <source
              srcset="../../assets/partners/partner-bg.webp"
              class="banner"
              type="image/webp"
              media="(max-width: 1920px)"
            />
            <img src="../../assets/partners/partner-bg.webp" class="banner" />
          </picture>
          <div class="banner-box">
            <picture class="banner-service zoom">
              <source
                srcset="../../assets/partners/partner-banner.webp"
                class="banner"
                type="image/webp"
                media="(max-width: 1920px)"
              />
              <img
                src="../../assets/partners/partner-banner.webp"
                :style="{ width: calBannerWidth }"
                class="banner"
              />
            </picture>
          </div>
        </div>
      </div>
      <div class="onlyMobile">
        <picture>
          <source
            srcset="../../assets/partners/m-partner-no.webp"
            class="banner"
            type="image/webp"
            media="(max-width: 1024px)"
          />
          <source
            srcset="../../assets/partners/m-partner-no.webp"
            class="banner"
            type="image/webp"
            media="(min-width: 576px)"
          />
          <img src="../../assets/partners/m-partner-no.webp" class="banner" />
        </picture>
        <div class="desc">
          We have continuosly established and expanded long-term relationships with our
          professional partners to facilitate the movement ogf goods, thus thousands of
          shipments have been delivered to every part of the world.
        </div>
      </div>
    </div>
    <div class="content">
      <template v-for="item in infoList">
        <div class="line-u">
          <img src="../../assets/partners/u.png" />
          <div>
            <span class="font1">{{ item.title }} </span>
            <span class="font2">{{ item.title2 }}</span>
          </div>
        </div>
        <div class="company-logo onlyDesktop">
          <a-row :gutter="48" class="company-row" v-for="item2 in item.list">
            <a-col :span="3" v-for="item3 in item2">
              <img :src="item3.url" />
            </a-col>
          </a-row>
        </div>
        <div class="company-logo onlyMobile">
          <a-row :gutter="48" class="company-row">
            <a-col :xs="{ span: 12 }" :xl="{ span: 3 }" v-for="item2 in item.fullList">
              <img :src="item2.url" />
            </a-col>
          </a-row>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { EventBus } from "../../util/eventBus.js";
export default {
  name: "Home",
  data() {
    return {
      scaleBannerWidth: 736,
      pcMaxWidth: 1920,
      mobileMaxWidth: 576,
      infoList: [
        {
          title: "Sea",
          title2: " Freight",
          fullList: [
            {
              url: require("../../assets/partners/sea/a01.png"),
            },
            {
              url: require("../../assets/partners/sea/a02.png"),
            },
            {
              url: require("../../assets/partners/sea/a03.png"),
            },
            {
              url: require("../../assets/partners/sea/a04.png"),
            },
            {
              url: require("../../assets/partners/sea/a05.png"),
            },
            {
              url: require("../../assets/partners/sea/a06.png"),
            },
            {
              url: require("../../assets/partners/sea/a07.png"),
            },
            {
              url: require("../../assets/partners/sea/a08.png"),
            },
            {
              url: require("../../assets/partners/sea/a09.png"),
            },
            {
              url: require("../../assets/partners/sea/a10.png"),
            },
            {
              url: require("../../assets/partners/sea/a11.png"),
            },
            {
              url: require("../../assets/partners/sea/a12.png"),
            },
            {
              url: require("../../assets/partners/sea/a13.png"),
            },
          ],
          list: [],
        },
        {
          title: "Air",
          title2: " Freight",
          fullList: [
            {
              url: require("../../assets/partners/air/b01.png"),
            },
            {
              url: require("../../assets/partners/air/b02.png"),
            },
            {
              url: require("../../assets/partners/air/b03.png"),
            },
            {
              url: require("../../assets/partners/air/b04.png"),
            },
            {
              url: require("../../assets/partners/air/b05.png"),
            },
            {
              url: require("../../assets/partners/air/b06.png"),
            },
            {
              url: require("../../assets/partners/air/b07.png"),
            },
            {
              url: require("../../assets/partners/air/b08.png"),
            },
            {
              url: require("../../assets/partners/air/b09.png"),
            },
            {
              url: require("../../assets/partners/air/b10.png"),
            },
            {
              url: require("../../assets/partners/air/b11.png"),
            },
          ],
          list: [],
        },
        {
          title: "Cross-border",
          title2: " E-commerce",
          fullList: [
            {
              url: require("../../assets/partners/cross/c01.png"),
            },
            {
              url: require("../../assets/partners/cross/c02.png"),
            },
            {
              url: require("../../assets/partners/cross/c03.png"),
            },
            {
              url: require("../../assets/partners/cross/c04.png"),
            },
          ],
          list: [],
        },
      ],
    };
  },
  mounted() {},
  computed: {
    calBannerWidth: {
      get() {
        this.handleWindowResize();
        return this.scaleBannerWidth;
      },
      set(newValue) {
        this.handleWindowResize();
      },
    },
  },
  created() {
    this.splitImg();
    EventBus.$on("windowResize", this.handleWindowResize);
  },
  destroyed() {
    EventBus.$off("windowResize", this.handleWindowResize);
  },

  methods: {
    splitImg() {
      this.infoList = this.infoList.map((item) => {
        const batch = this.customDivision(item.fullList.length, 5);
        const list = [];
        for (let index = 0; index < batch; index++) {
          const startIndex = index * 5;
          let lastIndex = (index + 1) * 5;
          if (lastIndex > item.fullList.length) {
            lastIndex = item.fullList.length;
          }

          list.push(item.fullList.slice(startIndex, lastIndex));
        }

        item["list"] = list;
        return item;
      });
    },
    customDivision(dividend, divisor) {
      // 计算商，并进行向下取整
      let quotient = Math.floor(dividend / divisor);

      // 如果有余数，则将商加1
      if (dividend % divisor !== 0) {
        quotient += 1;
      }

      return quotient;
    },
    handleWindowResize() {
      if (window.innerWidth > this.pcMaxWidth) return;
      this.scaleBannerWidth = (736 * window.innerWidth) / 1920 + "px";
    },

    computedWidth(originalWidth, maxWidth) {
      return (originalWidth * window.innerWidth) / maxWidth;
    },
  },
};
</script>

<style scoped lang="scss">
@import "partners.scss";
</style>
