<template>
  <footer :style="computedStyle">
    <div class="onlyWeb" >
      <div class="copyright">
        <img src="../../assets/footer-logo.png" />
        <CopyRight :auto="true" />
      </div>
      <div class="link">
        <Link :auto="true" />
      </div>
      <div>
        <Contact :auto="true" />
      </div>
    </div>
    <div class="onlyMobile" :style="computedStyle">
      <img src="../../assets/footer-logo.svg" style="width: 29vw" />
      <div class="content">
        <Link />
        <Contact />
        <CopyRight />
      </div>
      <div class="bg">
        <img src="../../assets/m-foot-bg.png" style="width: 19.1vw" />
      </div>
    </div>
  </footer>
</template>

<script>
import CopyRight from "./copyright/CopyRight.vue";
import Contact from "./contact/Contact.vue";
import Link from "./link/Link.vue";
import { EventBus } from "../../util/eventBus";
// import FooterNavbar from "../footerNavbar/FooterNavbar.vue";

export default {
  name: "Footer",
  components: {
    CopyRight,
    Contact,
    Link,
    // FooterNavbar
  },
  data() {
    return {
      bgColor: "#FEFEFE",
    };
  },
  computed: {
    computedStyle() {
      return {
        backgroundColor: this.bgColor,
      };
    },
  },
  created() {
    EventBus.$on("changeFooterColor", (color) => {
      console.log("44", color);
      this.bgColor = color;
    });
  },
  beforeDestroy() {
    EventBus.$off("changeFooterColor", () => {});
  },
};
</script>

<style lang="scss" scoped>
@import "footer.scss";
</style>
