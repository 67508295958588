<template>
    <a-collapse :bordered="false" v-model="activeKey" class="custom-collapse">
        <template #expandIcon>
            <div></div>
        </template>
        <a-collapse-panel key="contact" class="ant-collapse-content" :disabled="auto">
            <template #header>
                <div class="custom-collapse-header">
                    <span class="bold"> Contact Us</span>
                    <span class="custom-collapse-arrow" v-if="!auto">{{ activeKey.includes('contact') === true ? '-' : '+' }}</span>
                </div>
            </template>
            <span class="light">Add: 17256 S Main St, Gardena, CA 90248 USA<br /></span>
            <span class="light">Phone: (310) 961 9060<br /></span>
            <span class="light">Fax: (310) 961 9066</span>
            <br />
            <br />
            <p class="bold">Air Forwarding:</p>
            <span class="light">air@ultraaircargo.com</span>
            <p class="bold">Ocean Forwarding:</p>
            <span class="light">ocean@ultraaircargo.com</span>
            <p class="bold">Warehousing and distribution:</p>
            <span class="light">ocean@ultraaircargo.com</span>
            <p class="bold">Transloading:</p>
            <span class="light">ocean@ultraaircargo.com</span>
        </a-collapse-panel>
    </a-collapse>
</template>

<script>
	export default {
		name: "Contact",

		props: {
			auto: {
				type: Boolean,
				default: false
			}
		},

		data() {
			return {
				activeKey: []
			}
		},

		mounted() {
			if(this.auto) {
				this.activeKey.push('contact');
			}
		}
	}
</script>

<style lang="scss" scoped>
    @import "../foot-base.scss";
    @import "contact.scss";
</style>
