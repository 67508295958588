<template>
  <section>
    <div class="banner">
      <div class="onlyDesktop">
        <picture>
          <source
            srcset="../../assets/home/home-banner.webp"
            class="banner"
            type="image/webp"
            media="(max-width: 1920px)"
          />
          <img src="../../assets/home/home-banner.webp" class="banner" />
        </picture>
      </div>
      <div class="onlyMobile">
        <picture>
          <source
            srcset="../../assets/home/m-home-banner-normal.webp"
            class="banner"
            type="image/webp"
            media="(max-width: 1024px)"
          />
          <source
            srcset="../../assets/home/m-home-banner.webp"
            class="banner"
            type="image/webp"
            media="(min-width: 576px)"
          />
          <img src="../../assets/home/m-home-banner-normal.webp" class="banner" />
        </picture>
      </div>
    </div>
    <div class="content">
      <a-row>
        <a-col :lg="14" :xs="24">
          <div class="message">
            <img src="../../assets/home/home-logo.png" class="onlyDesktop" />
            <img src="../../assets/home/m-home-logo.png" class="onlyMobile" />
            <p>
              ULTRA AIR CARGO INC. was established in 1997. Our business specializes in
              freight forwarding services such as air transportation, ocean containers,
              ground transportation services, cross docking and warehousing.
            </p>
            <p>
              At Ultra Air Cargo, we strive every day to be the best business partner for
              our customers. Our team is problem-solving oriented which often results in
              cost-effective solutions for our customers. Everything we do is aimed at
              providing the best customer experience possible.
            </p>
            <p>
              We are proud of the high-level retention rate we have with our customers,
              and they thank us for their continued support by widening the relationship
              through the use of a wider range of solutions Ultra Air offers.
            </p>
          </div>
        </a-col>

        <a-col :lg="10" :xs="0">
          <div class="images-box">
            <img src="../../assets/home/home-03.webp" class="onlyDesktop home03 zoom" />
          </div>
        </a-col>
       
      </a-row>
      <img src="../../assets/home/m-home-03.webp" class="onlyMobile home03" />
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {

    };
  },
};
</script>

<style scoped lang="scss">
@import "home.scss";
</style>
