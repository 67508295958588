<template>
  <a-collapse :bordered="false" v-model="activeKey" class="custom-collapse">
    <template #expandIcon>
      <div></div>
    </template>
    <a-collapse-panel key="link" class="ant-collapse-content" :disabled="auto">
      <template #header>
        <div class="custom-collapse-header">
          <span>Links</span>
          <span class="custom-collapse-arrow" v-if="!auto">{{
            activeKey.includes("link") === true ? "-" : "+"
          }}</span>
        </div>
      </template>
      <span v-for="item in menuList" @click="jump(item)" class="link">{{ item.title }}<br /></span>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { EventBus } from "../../../util/eventBus";
export default {
  name: "Link",
  props: {
    auto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeKey: [],
      menuList: [
        {
          title: "Home",
          jump: "/",
          backGroudColor: "#c23a2a",
          footerColor: "#FEFEFE",
        },
        {
          title: "Services",
          jump: "/services",
          backGroudColor: "#FEFEFE",
          footerColor: "#FEFEFE",
        },
        {
          title: "About Us",
          jump: "/about",
          backGroudColor: "#FEFEFE",
          footerColor: "#FEFEFE",
        },
        {
          title: "Partners",
          jump: "/partners",
          backGroudColor: "#FEFEFE",
          footerColor: "#F8EAE8",
        },
        {
          title: "Contact Us",
          jump: "/contact",
          backGroudColor: "#FEFEFE",
          footerColor: "#FEFEFE",
        },
      ],
    };
  },
  mounted() {
    if (this.auto) {
      this.activeKey.push("link");
    }
  },
  methods: {
    jump(page) {
      EventBus.$emit("changeRouter", page.backGroudColor);
      EventBus.$emit("visibilitychange", page.jump);
      
      this.$router.push(page.jump);
      this.backTop()
    },
     // 回到顶部
     backTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../foot-base.scss";
  .link:hover {
    cursor: pointer;
  }
</style>
