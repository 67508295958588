<template>
  <section>
    <div class="onlyDesktop">
      <div class="banner">
        <picture>
          <source
            srcset="../../assets/about/about-bg.webp"
            class="banner"
            type="image/webp"
            media="(max-width: 1920px)"
          />
          <img src="../../assets/about/about-bg.webp" class="banner" />
        </picture>
        <div class="banner-box">
          <picture class="banner-service zoom">
            <source
              srcset="../../assets/about/about-banner.webp"
              class="banner"
              type="image/webp"
              media="(max-width: 1920px)"
            />
            <div :style="{ width: calBannerWidth }">
              <img src="../../assets/about/about-banner.webp" class="banner" />
            </div>
          </picture>
        </div>
      </div>
    </div>
    <div class="onlyMobile">
      <picture>
        <source
          srcset="../../assets/about/m-about-no.webp"
          class="banner"
          type="image/webp"
          media="(max-width: 1024px)"
        />
        <source
          srcset="../../assets/about/m-about-no.webp"
          class="banner"
          type="image/webp"
          media="(min-width: 576px)"
        />
        <img src="../../assets/about/m-about-no.webp" class="banner" />
      </picture>

      <div class="desc">
        Our key vision is to provide not only a communicative and trustworthy freight
        forwarder but also a greater level of service at a cheaper cost and a faster
        speed.
      </div>
    </div>
    <div class="content">
      <a-row>
        <a-col :lg="14" :xs="24">
          <div class="message">

            <img src="../../assets/home/home-logo.png" class="onlyDesktop logoImg" />
            <img src="../../assets/about/logo_AboutUs3_Mobile.png" class="onlyMobile" />
            <p>
              We began operation in 1997 by forwarding small shipments from United States
              to Asia.
            </p>
            <p>
              Initially, our primary goal was to be a trustworthy provider with whom
              clients can communicate at any time.
            </p>
            <p>
              Over time, we have continuously established and expanded long-term
              relationships with our professional partners to facilitate the movement of
              goods, with thousands of shipments delivered to every part of the world. In
              our business, client satisfaction constantly inspires us to expand and
              flourish in our industry.
            </p>
            <p>
              Now, our key vision is to provide not only a communicative and trustworthy
              freight forwarder but also a greater level of service at a cheaper cost and
              a faster speed.
            </p>
          </div>
        </a-col>

        <a-col :lg="10" :xs="0">
          <div class="about-img">
            <img src="../../assets/about/aboutus.png" class="onlyDesktop leftImg" />
          </div>
        </a-col>
      </a-row>

      <img
        src="../../assets/about/banner_AboutUs4_Mobile.png"
        class="onlyMobile home03"
      />
    </div>
  </section>
</template>

<script>
import { EventBus } from "../../util/eventBus.js";
export default {
  name: "Home",
  data() {
    return {
      scaleBannerWidth: 736,
      maxWidth: 1920,
    };
  },
  mounted() {},
  created() {
    EventBus.$on("windowResize", this.handleWindowResize);
  },

  destroyed() {
    EventBus.$off("windowResize", this.handleWindowResize);
  },

  computed: {
    calBannerWidth: {
      get() {
        this.handleWindowResize();
        return this.scaleBannerWidth;
      },
      set(newValue) {
        this.handleWindowResize();
      },
    },
  },

  methods: {
    // Calculate the width of the banner image
    computedWidth(originalWidth, maxWidth) {
      return (originalWidth * window.innerWidth) / maxWidth;
    },
    handleWindowResize() {
      if (window.innerWidth > this.maxWidth) return;
      this.scaleBannerWidth = (736 * window.innerWidth) / 1920 + "px";
    },
  },
};
</script>

<style scoped lang="scss">
@import "about.scss";
</style>
