<template>
    <a-collapse :bordered="false" v-model="activeKey"  class="custom-collapse">
        <template #expandIcon>
            <div></div>
        </template>
        <a-collapse-panel key="copyRight" class="ant-collapse-content" :disabled="auto">
            <template #header>
                <div class="custom-collapse-header">
                    <span>Copyright</span>
                    <span class="custom-collapse-arrow" v-if="!auto">{{ activeKey.includes('copyRight') === true ? '-' : '+' }}</span>
                </div>
            </template>
            <span class="text">Copyright 2024 Ultra Air Cargo Inc.<br /></span>
            <span>All rights reserved</span>
        </a-collapse-panel>
    </a-collapse>
</template>

<script>
	export default {
		name: "CopyRight",

        props: {
		    auto: {
		    	type: Boolean,
                default: false
            }
        },

        data() {
			return {
                activeKey: []
            }
        },

        mounted() {
		    if(this.auto) {
		    	this.activeKey.push('copyRight');
            }
        }
	}
</script>

<style lang="scss" scoped>
    @import "../foot-base.scss";
    .text {
        white-space: nowrap;
        overflow: visible;
    }
</style>
