<template>
    <header>
        <Navbar></Navbar>
    </header>
</template>

<script>
    import Navbar from "../navbar/Navbar.vue";
	export default {
		name: "Header",
        components: {
            Navbar
        }
	}
</script>

<style scoped lang="scss">
    @import "header.scss";
</style>
