<template>
  <section>
    <div class="onlyDesktop">
      <div class="banner">
        <picture>
          <source
            srcset="../../assets/services/service-bg.webp"
            class="banner"
            type="image/webp"
            media="(max-width: 1920px)"
          />
          <img src="../../assets/services/service-bg.webp" class="banner" />
        </picture>
        <div class="banner-box">
          <picture class="banner-service zoom">
            <source
              srcset="../../assets/services/service-banner.webp"
              class="banner"
              type="image/webp"
              media="(max-width: 1920px)"
            />
            <img
              src="../../assets/services/service-banner.webp"
              :style="{ width: calBannerWidth }"
              class="banner"
            />
          </picture>
        </div>
      </div>
      <div class="content">
        <div class="service-box">
          <div class="service-item">
            <img
              src="../../assets/services/icon-s-air.png"
              :style="{ width: computedWidth(150, pcMaxWidth) + 'px' }"
            />
            <span>
              <span>AIR FREIGHT </span>
              <span>FORWARDING</span>
              <p>
                We are TSA compliant and approved. We interact with a variety of airlines
                companies to deliver your goods on time and in full to their final
                destination.
              </p>
              <p>
                We offer End-to-end services or any part of the supply chain you’d like us
                to manage on your behalf.
              </p>
            </span>
          </div>
          <div class="service-item">
            <img
              src="../../assets/services/icon-s-ocean.png"
              :style="{ width: computedWidth(150, pcMaxWidth) + 'px' }"
            />
            <span>
              <span>OCEAN FREIGHT </span>
              <span>FORWARDING</span>
              <p>
                We are an FMC member and contract with Steamship line to provide Global
                Ocean shipping services on full container and less than a container load.
              </p>
            </span>
          </div>
          <div class="service-item">
            <img
              src="../../assets/services/icon-s-broke.png"
              :style="{ width: computedWidth(150, pcMaxWidth) + 'px' }"
            />
            <span>
              <span>BROKERAGE </span>
              <span>SERVICES</span>
              <p>
                We also offer customs brokerage services for any goods imported into the
                United States of America.
              </p>
            </span>
          </div>
          <div class="service-item">
            <img
              src="../../assets/services/icon-s-house.png"
              :style="{ width: computedWidth(150, pcMaxWidth) + 'px' }"
            />
            <span>
              <span>WAREHOUSING </span>
              <span></span>
              <p>
                Our warehouses have the capability to hold large amounts of inventory
                throughout the year. We can store your inventory, whether it is only for a
                few days or a few months; we have a solution for your needs.
              </p>
            </span>
          </div>
          <div class="service-item">
            <img
              src="../../assets/services/icon-s-cross.png"
              :style="{ width: computedWidth(150, pcMaxWidth) + 'px' }"
            />
            <span>
              <span>CROSSDOCKING and LAST MILE DELIVERY </span>
              <span>SERVICES</span>
              <p>
                Our dedicated team can also support you with any cross-dock requirements
                you may have.
              </p>
              <p>
                We also have a cost-effective network of last mile delivery partners that
                can perform to-the-door deliveries to and from anywhere in North America,
                offering excellent quality and proven on-time delivery performance.
              </p>
            </span>
          </div>
          <div class="service-item">
            <img
              src="../../assets/services/icon-s-truck.png"
              :style="{ width: computedWidth(150, pcMaxWidth) + 'px' }"
            />
            <span>
              <span>TRUCKING </span>
              <span>SERVICES</span>
              <p>
                As member of D.O.T. (U.S. Department of Transportation) we pride ourselves
                by providing our customers with truck load and less than a truck load
                transportation service.
              </p>
            </span>
          </div>
        </div>
        <div class="images">
          <div class="images-01">
            <img
              src="../../assets/services/service-01.webp"
              :style="{ width: computedWidth(585, pcMaxWidth) + 'px' }"
              class="zoom"
            />
          </div>
          <div class="images-02">
            <img
              src="../../assets/services/service-02.webp"
              :style="{ width: computedWidth(644, pcMaxWidth) + 'px' }"
              class="zoom"
            />
          </div>
          <div>
            <img
              src="../../assets/services/service-03.webp"
              :style="{ width: computedWidth(693, pcMaxWidth) + 'px' }"
              class="zoom"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="onlyMobile">
      <div class="banner">
        <picture>
          <source
            srcset="../../assets/services/m-service-banner-normal.webp"
            class="banner"
            type="image/webp"
            media="(max-width: 1024px)"
          />
          <source
            srcset="../../assets/services/m-service-banner.webp"
            class="banner"
            type="image/webp"
            media="(min-width: 576px)"
          />
          <img src="../../assets/services/m-service-banner-normal.webp" class="banner" />
        </picture>
        <span>
          Our business specializes in air transportation, ocean transportation,
          distribution, and warehousing. We prioritize customer's need, exceeding their
          expectations and becoming a trustworthy provider.
        </span>
      </div>
      <div class="content">
        <div>
          <div class="service-box">
            <div class="service-item">
              <img src="../../assets/services/m-icon-s-air.png" />
              <span
                >AIR FREIGHT
                <p>FORWARDING</p></span
              >
            </div>
            <span>
              We are TSA compliant and approved. We interact with a variety of airlines
              companies to deliver your goods on time and in full to their final
              destination. We offer End-to-end services or any part of the supply chain
              you’d like us to manage on your behalf.
            </span>
            <div class="service-item">
              <img src="../../assets/services/m-icon-s-ocean.png" />
              <span
                >OCEAN FREIGHT
                <p>FORWARDING</p></span
              >
            </div>
            <span>
              We are an FMC member and contract with Steamship line to provide Global
              Ocean shipping services on full container and less than a container load.
            </span>
            <div class="service-item">
              <img src="../../assets/services/m-icon-s-broke.png" />
              <span
                >BROKERAGE
                <p>SERVICES</p></span
              >
            </div>
            <span>
              We also offer customs brokerage services for any goods imported into the
              United States of America.
            </span>
            <div class="service-item">
              <img src="../../assets/services/m-icon-s-house.png" />
              <span>WAREHOUSING </span>
            </div>
            <span>
              Our warehouses have the capability to hold large amounts of inventory
              throughout the year. We can store your inventory, whether it is only for a
              few days or a few months; we have a solution for your needs.
            </span>
            <div class="service-item">
              <img src="../../assets/services/m-icon-s-cross.png" />
              <span
                >CROSSDOCKING and LAST MILE DELIVERY
                <p>SERVICES</p></span
              >
            </div>
            <span>
              Our dedicated team can also support you with any cross-dock requirements you
              may have. We also have a cost-effective network of last mile delivery
              partners that can perform to-the-door deliveries to and from anywhere in
              North America, offering excellent quality and proven on-time delivery
              performance.
            </span>
            <div class="service-item">
              <img src="../../assets/services/m-icon-s-truck.png" />
              <span
                >TRUCKING
                <p>SERVICES</p></span
              >
            </div>
            <span>
              As member of D.O.T. (U.S. Department of Transportation) we pride ourselves
              by providing our customers with truck load and less than a truck load
              transportation service.
            </span>
          </div>
        </div>
      </div>
      <div class="images">
        <img
          src="../../assets/services/m-service-01.webp"
          :style="{ width: computedWidth(576, mobileMaxWidth) + 'px' }"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { EventBus } from "../../util/eventBus.js";

export default {
  name: "Services",
  data() {
    return {
      scaleBannerWidth: 736,
      pcMaxWidth: 1920,
      mobileMaxWidth: 576,
    };
  },

  created() {
    EventBus.$on("windowResize", this.handleWindowResize);
  },

  destroyed() {
    EventBus.$off("windowResize", this.handleWindowResize);
  },

  computed: {
    calBannerWidth: {
      get() {
        this.handleWindowResize();
        return this.scaleBannerWidth;
      },
      set(newValue) {
        this.handleWindowResize();
      },
    },
  },

  methods: {
    handleWindowResize() {
      if (window.innerWidth > this.pcMaxWidth) return;
      this.scaleBannerWidth = (736 * window.innerWidth) / 1920 + "px";
    },

    computedWidth(originalWidth, maxWidth) {
      return (originalWidth * window.innerWidth) / maxWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "services.scss";
</style>
