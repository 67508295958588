<template xmlns:v-on="http://www.w3.org/1999/xhtml">
  <header>
    <div class="imagesButtonContainer">
      <button v-on:click="toggle">
        <img :src="icon" alt="Open and close mobile menu" v-show="!visible" />
      </button>
    </div>
    <Navbar v-show="visible" @close="close"></Navbar>
  </header>
</template>

<script>
import Navbar from "../navbar/Navbar.vue";
import { EventBus } from "../../util/eventBus";
export default {
  name: "HeaderMobile",
  components: {
    Navbar,
  },
  data() {
    return {
      bgColor: "",
      visible: false,
      icon: require("../../assets/menus.png"),
    };
  },
  computed: {
    computedStyle() {
      return {
        backgroundColor: this.bgColor,
      };
    },
  },
  created() {
    EventBus.$on("changeBackgroud", (color) => {
      console.log("44", color);
      this.bgColor = color;
      let imgName = this.getTextColor(this.bgColor);
      this.icon = require("../../assets/" + imgName);
    });
  },
  beforeDestroy() {
    EventBus.$off("changeBackgroud", () => {});
  },
  methods: {
    getTextColor(hexColor) {
      const brightness = this.getBrightness(hexColor);
      console.log(brightness);
      return brightness > 186 ? "menu_mobile_red.png" : "menus.png";
    },
    getBrightness(hexColor) {
      const r = parseInt(hexColor.substr(1, 2), 16);
      const g = parseInt(hexColor.substr(3, 2), 16);
      const b = parseInt(hexColor.substr(5, 2), 16);

      return r * 0.299 + g * 0.587 + b * 0.114;
    },
    toggle() {
      this.visible = !this.visible;
    },

    close() {
      this.toggle();
    },
  },
};
</script>

<style scoped lang="scss">
@import "headerMobile.scss";

button {
  appearance: auto;
  -webkit-writing-mode: horizontal-tb !important;
}
</style>
