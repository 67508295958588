<template>
  <main class="container" :style="computedStyle" ref="container">
    <div class="content">
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
import Header from "../header/Header.vue";
import HeaderMobile from "../headerMobile/HeaderMobile.vue";
import Home from "../home/Home.vue";
import { EventBus } from "../../util/eventBus";
export default {
  name: "Main",
  components: {
    Header,
    HeaderMobile,
    Home,
  },

  data() {
    return {
      bgColor: "",
    };
  },
  computed: {
    computedStyle() {
      return {
        backgroundColor: this.bgColor,
      };
    },
  },
  created() {
    EventBus.$on("changeBackgroud", changeBackgroud);
  },
  beforeDestroy() {
    EventBus.$off("changeBackgroud", () => {});
  },
  mounted() {
    //this.loadBanner();
  },

  methods: {
    changeBackgroud(color) {
      this.bgColor = color;
    },
    loadBanner() {
      const clearImage = "http://139.224.203.208:11000/down/qFHdC5yctKrV.png";
      const img = new Image();
      img.onload = () => {
        this.bannerImage = clearImage;
      };
      img.src = this.bannerImage;
    },
  },
};
</script>

<style scoped lang="scss">
@import "main.scss";
</style>
