<template>
  <nav v-show="visible">
    <a @click="close"><img src="../../assets/close.png" /></a>
    <ul>
      <li v-for="menu in menuList" :class="{ active: thisRouter == menu.jump }">
        <a :style="computedStyle" @click="jump(menu)">{{ menu.title }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { EventBus } from "../../util/eventBus";
export default {
  name: "Navbar",
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      thisRouter: "/",
      backGroudColor: "#c23a2a",
      footerColor: "#FEFEFE",
      menuList: [
        {
          title: "Home",
          jump: "/",
          backGroudColor: "#c23a2a",
          footerColor: "#FEFEFE",
        },
        {
          title: "Services",
          jump: "/services",
          backGroudColor: "#FEFEFE",
          footerColor: "#FEFEFE",
        },
        {
          title: "About Us",
          jump: "/about",
          backGroudColor: "#FEFEFE",
          footerColor: "#FEFEFE",
        },
        {
          title: "Partners",
          jump: "/partners",
          backGroudColor: "#FEFEFE",
          footerColor: "#F8EAE8",
        },
        {
          title: "Contact Us",
          jump: "/contact",
          backGroudColor: "#FEFEFE",
          footerColor: "#FEFEFE",
        },
      ],
    };
  },
  created() {
    this.reloadColor();
  },
  mounted() {
    document.addEventListener("visibilitychange", this.reloadColor);
    setInterval(() => {
      this.thisRouter = this.$router.history.current.path;
    }, 500);
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.reloadColor);
  },
  computed: {
    computedStyle() {
      return {
        color: this.getTextColor(this.backGroudColor),
      };
    },
  },
  watch: {
    backGroudColor(newVal) {
      console.log("66");
      EventBus.$emit("changeBackgroud", newVal);
    },
    thisRouter(newVal) {
      console.log("ssssssss", newVal);
      EventBus.$emit("changeRouter", newVal);
      this.reloadColor();
    },
    footerColor(newVal) {
      console.log("77");
      EventBus.$emit("changeFooterColor", newVal);
    },
  },
  methods: {
    reloadColor() {
      console.log("66")
      this.thisRouter = this.$router.history.current.path;
      const thisItem = this.menuList.filter((item) => item.jump == this.thisRouter)[0];
      this.backGroudColor = thisItem.backGroudColor;
      this.footerColor = thisItem.footerColor;
    },
    close() {
      this.$emit("close");
    },
    jump(page) {
      this.thisRouter = page.jump;
      EventBus.$emit("changeRouter", page.jump);
      this.backGroudColor = page.backGroudColor;
      this.footerColor = page.footerColor;
      this.$router.push(page.jump);
      this.close();
      this.backTop()
    },
    // 回到顶部
    backTop() {
      document.documentElement.scrollTop = 0;
    },
    getTextColor(hexColor) {
      const brightness = this.getBrightness(hexColor);
      console.log(brightness);
      return brightness > 186 ? "#C23A2A" : "#FFFFFF";
    },
    getBrightness(hexColor) {
      const r = parseInt(hexColor.substr(1, 2), 16);
      const g = parseInt(hexColor.substr(3, 2), 16);
      const b = parseInt(hexColor.substr(5, 2), 16);

      return r * 0.299 + g * 0.587 + b * 0.114;
    },
    zoom() {
      console.log("窗口缩放比例:", window.devicePixelRatio);
      console.log("窗口宽度:", window.innerWidth);
    },
  },
};
</script>

<style scoped lang="scss">
@import "navbar.scss";
</style>
